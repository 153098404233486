.App {
  text-align: center;
  font-family: 'Anybody', sans-serif !important;
  position: relative;
}

nav {
  background: #333;
  padding: 10px 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  color: #21484F;
  text-decoration: none;
  font-size: 18px;
}


header {
  background-image: linear-gradient(to bottom, #000000ca, #000), url('./assets/bg.jpg');
  background-size: cover;
  background-position: center;
  min-height: 968px; /* Ajusta la altura según tu imagen */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
}

header::after {
  content:'';
  width: 100%;
  height: 7rem;
  bottom:-1px;
  display: grid;
  position: absolute;
  /* background: linear-gradient(to top, #fff, #fff,#00000000); */
}

body {
  margin: 0;
  font-family: 'Anybody', sans-serif !important;
  color: #cab861;
}

.header-content {
  display: flex;
  align-items: center;
  max-width: 1500px;
  width: 100%;
}

.text {
  flex: 1;
  text-align: left;
  padding: 20px;
  color: #fff;
}

.image {
  flex: 1;
}

.image img {
  max-width: 100%;
}

.images img {
  max-width: 500px;
  width: 100%;
}
 
 

.d-none {
  display: none;
} 


.section-968 {
  min-height: 968px;
  height: auto;
  width: 100%;
  background: #000;
} 


@media screen and (min-width:0px) and (max-width:992px) {


  .max-header-width {
    max-width: 450px !important;
    margin: 0 auto;
  }

  .box-moviendo-mm {
    align-content: start !important;
  }

  header {
    min-height: 767px;
    height: auto;
    width: 100%;
  } 


  .images img {
    max-width: 300px;
    width: 100%;
  }
   
  

  .section-968 {
    min-height: 767px;
    height: auto;
    width: 100%;
  } 

  .box-moviendo-mm {
    min-height: 767px;
    height: auto;
    display: grid;
    align-items: center;
  }


}


.section {
  margin-top: 100px;
  min-height: 550px;
  height: auto;
  width: 100%;
} 

.Primary-Size {
  font-size: 6rem;
  line-height: 5.5rem;
  color: #21484F;
  max-width: 600px;
  font-weight: 900;
} 



.mm-nav {
  background: #fff;
  color: #21484F;
  position: fixed;
  top:0;
  width: 100%;
  display: grid;
  align-items: center;
  height: 4rem;
  text-align: center;
  box-shadow: 1px 2px 8px #0003;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
} 

.LogoMoviendo {
  height: 137px;
  width: auto;
}

.logo {
  align-content: center;
  padding: 1px 0px;
  margin-top: 7px;
}



.box-item {
  display: grid;
  grid-template-columns: auto auto auto;
  max-width: 1500px;
  margin: 0 auto; 
  padding: 20px; 
} 

.box-item-0 {
  display: grid;
  grid-template-columns: auto;
  max-width: 1500px;
  margin: 0 auto; 
  padding: 20px; 
}

.mapview-box {
  display: grid;
  grid-template-columns: auto;
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px; 
}

.mapview-box img {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  height: auto;
  
}


.item-stock img {
  width:auto;
  height: 100px;
} 

.item-stock h3 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.item-stock p {
  font-size: 1.3rem;
  font-weight: 300 !important;
}

 h2 {
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0;
} 


.max-500  {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}


.box-input-range {
  display: grid;
  grid-template-columns: auto auto;
}
.range-item {
  display: grid;
  grid-template-columns: auto;
  margin-top: 30px;
}
.range-item input {
  display: grid;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
} 

label {
  font-size: 2.0rem;
  font-weight: 800 !important;
}

@media screen and (min-width:0px) and (max-width:992px) {

  
.box-input-range {
  display: grid;
  grid-template-columns: auto;
}
  
.box-item {
  display: grid;
  grid-template-columns: auto;
  max-width: 1500px;
  margin: 0 auto; 
} 

.item-stock {
  margin-top: 230px;
}

.Primary-Size {
  font-size: 4rem;
  line-height: 3.5rem;
  color: #21484F;
  max-width: 600px;
  font-weight: 900;
} 

.mm-nav {
  grid-template-columns: auto;
}



}


.section-white {

  background-size: cover;
  background-position: center;
  min-height: 968px; /* Ajusta la altura según tu imagen */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  height: auto;
  background: #151515f5;
}
